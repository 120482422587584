var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"ma-0",attrs:{"absolute":"","color":"blue lighten-5"}},[_c('v-icon',[_vm._v("mdi-lan-connect")]),_c('v-toolbar-title',[_vm._v("Dispositivos SSH")]),_c('v-spacer'),_c('v-text-field',{staticClass:"text-field-transparent",attrs:{"hide-details":"","dense":"","filled":"","persistent-placeholder":"","placeholder":"Digite as informações de pesquisa","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-end",value:('Menu geral do sistema'),expression:"'Menu geral do sistema'",modifiers:{"bottom-end":true}}],staticClass:"pa-2 ml-2",attrs:{"elevation":"2","icon":_vm.$vuetify.breakpoint.mobile,"small":_vm.$vuetify.breakpoint.mobile,"color":"secondary"},on:{"click":_vm.setMenuMobile}},[_vm._v(" "+_vm._s(_vm.msgButton("Menu"))+" "),(!_vm.$store.state.isMenuVisibleMobile)?_c('v-icon',[_vm._v("mdi-menu-down")]):_c('v-icon',[_vm._v("mdi-menu")])],1)],1),_c('v-container',{staticClass:"mt-15",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dispositivos,"search":_vm.search,"item-key":"porta_host","show-head":""},scopedSlots:_vm._u([{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cnpj)+" ")]}},{key:"item.nome_empresa",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome_empresa)+" ")]}},{key:"item.nome_dispositivo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome_dispositivo)+" ")]}},{key:"item.porta_host",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.porta_host)+" ")]}},{key:"item.porta_client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.porta_client)+" ")]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.descricao)+" ")]}},{key:"item.token",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.token)+" ")]}},{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.uuid)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }