<template>
  <div>
    <!-- App Bar -->
    <v-app-bar absolute color="blue lighten-5" class="ma-0">
      <v-icon>mdi-lan-connect</v-icon>
      <v-toolbar-title>Dispositivos SSH</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        hide-details
        dense
        filled
        class="text-field-transparent"
        v-model="search"
        persistent-placeholder
        placeholder="Digite as informações de pesquisa"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        elevation="2"
        :icon="$vuetify.breakpoint.mobile"
        :small="$vuetify.breakpoint.mobile"
        color="secondary"
        class="pa-2 ml-2"
        @click="setMenuMobile"
        v-tooltip.bottom-end="'Menu geral do sistema'"
      >
        {{ msgButton("Menu") }}
        <v-icon v-if="!$store.state.isMenuVisibleMobile">mdi-menu-down</v-icon>
        <v-icon v-else>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Content Area -->
    <v-container fluid class="mt-15">
      <v-data-table
        :headers="headers"
        :items="dispositivos"
        :search="search"
        item-key="porta_host"
        class="elevation-1"
        show-head
      >
        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj }}
        </template>
        <template v-slot:item.nome_empresa="{ item }">
          {{ item.nome_empresa }}
        </template>
        <template v-slot:item.nome_dispositivo="{ item }">
          {{ item.nome_dispositivo }}
        </template>
        <template v-slot:item.porta_host="{ item }">
          {{ item.porta_host }}
        </template>
        <template v-slot:item.porta_client="{ item }">
          {{ item.porta_client }}
        </template>
        <template v-slot:item.descricao="{ item }">
          {{ item.descricao }}
        </template>
        <template v-slot:item.token="{ item }">
          {{ item.token }}
        </template>
        <template v-slot:item.uuid="{ item }">
          {{ item.uuid }}
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";

export default {
  name: "DispositivosSSHViewer",
  data() {
    return {
      search: "",
      dispositivos: [],
      headers: [
        { text: "CNPJ", value: "cnpj" },
        { text: "Empresa", value: "nome_empresa" },
        { text: "Dispositivo", value: "nome_dispositivo" },
        { text: "Porta Host", value: "porta_host" },
        { text: "Porta Cliente", value: "porta_client" },
        { text: "Descrição", value: "descricao" },
        { text: "Token", value: "token" },
        { text: "UUID", value: "uuid" },
      ],
    };
  },
  methods: {
    fetchDispositivos() {
      axios
        .get(`${baseApiUrl}/portas_ssh_all`)
        .then((response) => {
          this.dispositivos = response.data;
        })
        .catch((error) => {
          console.error("Erro ao buscar dispositivos:", error);
        });
    },

    setMenuMobile() {
      this.$store.state.isMenuVisibleMobile = !this.$store.state
        .isMenuVisibleMobile;
    },
    msgButton: function(value) {
      return this.$vuetify.breakpoint.mobile ? "" : value;
    },
  },
  mounted() {
    this.fetchDispositivos();
  },
};
</script>

<style scoped></style>
